import MainVideoPlayer from "./MainVideoPlayer/MainVideoPlayer";
import TickerBar from "./TickerBar/TickerBar";
import Blogs from "./Blogs/Blogs";
import Ad from "./Ad/Ad";
import RecentVideos from "./RecentVideos/RecentVideos";
import StayUpdated from "./StayUpdated/StayUpdated";
import Divider from "./Divider/Divider";
import { Component } from "react";

export default class Home extends Component {
  render() {
    //filting listed videos only ("anybody","unlisted")
    let x = 0;
    (this.props.recentVideos[0]?.privacy.view === "anybody" && this.props.recentVideos[0]?.status ==="available") ? (x = 0) : (x = 1);
    return (
      <div>
        <div className="row">
          <div className="col-lg-7">
            <MainVideoPlayer video={this.props.recentVideos[x]} />
          </div>
          <div className="col-lg-5 ">
            <div className="col-12">
              <h2 className="dark-yellow">Get In The Game</h2>

            </div>
            {/* loop Affiliate Ads */}
            {new Array(20).fill(0).map((item, index) => {
              return (
                <Ad type="affiliate" position={index + 1} key={index} ads={this.props.ads} />
              );
            })}
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <TickerBar content={this.props.ticker} />
          </div>
        </div>

        <Divider text={"RECENT VIDEOS"}></Divider>
        <div className="row pb-4">
          <div className="col-12">
            <RecentVideos videos={this.props.recentVideos}></RecentVideos>
          </div>
        </div>

        <Divider text={"DON'T MISS OUT"}></Divider>
        <div className="row mt-4">
          <div className="col-lg-9 vertical-line">
            {/* Ads Position  */}
            <div className="mt-4 mb-4 text-center">
              <Ad type="leaderboard" ads={this.props.ads} />
            </div>
            {/* Blog Position */}
            <div className="mt-4 mb-4 ">
              <Blogs
                categories={this.props.categories}
                selectedBlogs={this.props.blogs}
                ads={this.props.ads}
              />
            </div>
          </div>

          {/* RightSide and Ads */}
          <div className="col-3 DisableOnMobile">
            <div className="p-2 white x-large reduce-height">
              <b>
                {" "}
                Helping
                <br />
                You
                <br />
                <span className="dark-yellow">Bust</span>
                <br />
                The
                <br />
                Book
              </b>
            </div>
            <div className="mt-4 mb-4 ">
              <Ad type="skyscraper" ads={this.props.ads} />
            </div>
            <div className="mt-4 mb-4 ">
              <StayUpdated content={this.props.blogs}></StayUpdated>
            </div>
            <div className="mt-4 mb-4 ">
              <Ad type="skyscraper" ads={this.props.ads} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
