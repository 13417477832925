import "./App.scss";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Header from "./components/Header/Header";
import SideNav from "./components/SideNav/SideNav";
import Home from "./components/Home";
import Blogs from "./components/Blogs/Blogs";
import BlogExpanded from "./components/BlogExpanded";
import Footer from "./components/Footer/Footer";
import Videos from "./components/Videos/Videos";
import Experts from "./components/Experts/Experts";
import Calendar from "./components/Calendar";
import Contact from "./components/Contact";
import Terms from "./components/Terms";
import Mailchimp from "./components/Mailchimp";

import {
  fetchAds,
  fetchBlogByCat,
  fetchExperts,
  fetchTickerbarContent,
  getRecentVideos,
  getRecommendedVideos,
} from "./fetchService";

import axios from "axios";
import { fetchCategories } from "./fetchService";
import { useState, createContext, useContext, Component } from "react";

const appContext = createContext();

const Provider = ({ children }) => {
  const provider = useProvider();
  return <appContext.Provider value={provider}>{children}</appContext.Provider>;
};

// Detects the geo location
const useProvider = () => {
  const ipstackURL = `https://api.ipstack.com/check?access_key=${process.env.REACT_APP_IPSTACK_KEY}`;
  const [country, setCountry] = useState("");
  axios.get(ipstackURL).then((res) => {
    setCountry(res.data.country_code);
  });
  return { country };
};

export const useProvideContext = () => useContext(appContext);

export default class App extends Component {
  constructor() {
    super();
    this.state = {
      categories: [],
      categoryFilterEnabled: false,
      experts: [],
      recentVideos: [],
      recommendedVideos: [],
      ads: [],
      ticker: [],
      blogs: [],
    };
    this.changeCategory = this.changeCategory.bind(this);
  }

  async componentDidMount() {
    this.setState({
      experts: await fetchExperts(),
      categories: await fetchCategories(),
      ads: await fetchAds(),
      ticker: await fetchTickerbarContent(),
    });
    // done later as they depend on categories
    this.updateVideos();
    this.updateBlogs();
  }

  changeCategory(category) {
    let updatedCategories;
    let categoryFilterEnabled = this.state.categoryFilterEnabled;
    if (category === "ALL") {
      this.resetCategories();
    } else {
      // first selection of filter
      if (!this.state.categoryFilterEnabled) {
        // set all categories but the selected to visible false
        updatedCategories = this.state.categories.map((cat) => {
          if (cat.name === category) {
            cat.visible = true;
          } else {
            cat.visible = false;
          }
          return cat;
        });
        categoryFilterEnabled = true;
      } else {
        // toggle
        updatedCategories = this.state.categories.map((cat) => {
          if (cat.name === category) {
            // toggle visibility
            cat.visible = !cat.visible;
          }
          return cat;
        });
      }
      // if no active category left
      if (!this.state.categories.find((item) => item.visible)) {
        this.resetCategories();
      } else {
        this.setState(
          {
            categories: updatedCategories,
            categoryFilterEnabled: categoryFilterEnabled,
          },
          async () => {
            await this.updateVideos();
            await this.updateBlogs();
          }
        );
      }
    }
  }
  resetCategories() {
    const updatedCategories = this.state.categories.map((cat) => {
      cat.visible = true;
      return cat;
    });
    this.setState(
      {
        categories: updatedCategories,
        categoryFilterEnabled: false,
      },
      async () => {
        await this.updateVideos();
        await this.updateBlogs();
      }
    );
  }
  async updateVideos() {
    getRecentVideos(this.state.categories).then((res) => {
      this.setState({ recentVideos: res });
    });
    getRecommendedVideos(this.state.categories).then((res) => {
      this.setState({ recommendedVideos: res });
    });
  }
  async updateBlogs() {
    this.setState({
      blogs: await fetchBlogByCat(this.state.categories),
    });
  }

  render() {
    return (
      <Provider>
        <Router>
          <div className="AppBackground">
            <div className="container-fluid">
              <Header
                categories={this.state.categories}
                changeCategory={this.changeCategory}
                ads={this.state.ads}
              />
              <div className="row">
                <div className="col-lg-2">
                  <SideNav ads={this.state.ads} />
                </div>
                <div className="col-lg-10">
                  <div className="page-container">
                    <div className="content-wrap">
                      <div className="col-lg-11">
                        {/* A <Switch> looks through its children <Route>s and renders the first one that matches the current URL. */}
                        <Switch>
                          <Route path="/blog/:blogId">
                            <BlogExpanded
                              categories={this.state.categories}
                              selectedBlogs={this.state.blogs}
                              ads={this.state.ads}
                              blogs={this.state.blogs}
                            />
                          </Route>
                          <Route path="/blog">
                            <Blogs
                              blogs={this.state.blogs}
                              selectedBlogs={this.state.blogs}
                              fullContent={true}
                              categories={this.state.categories}
                              ads={this.state.ads}
                            />
                          </Route>
                          <Route path="/videos/:videoId">
                            <Videos
                              categories={this.state.categories}
                              recentVideos={this.state.recentVideos}
                              recommendedVideos={this.state.recommendedVideos}
                              blogs={this.state.blogs}
                            />
                          </Route>
                          <Route path="/videos">
                            <Videos
                              categories={this.state.categories}
                              recentVideos={this.state.recentVideos}
                              recommendedVideos={this.state.recommendedVideos}
                              blogs={this.state.blogs}
                            />
                          </Route>
                          <Route path="/experts">
                            <Experts
                              experts={this.state.experts}
                              recentVideos={this.state.recentVideos}
                              recommendedVideos={this.state.recommendedVideos}
                            />
                          </Route>
                          <Route path="/calendar">
                            <Calendar />
                          </Route>
                          <Route path="/contact">
                            <Contact
                              ads={this.state.ads}
                              blogs={this.state.blogs}
                            />
                          </Route>
                          <Route path="/terms">
                            <Terms />
                          </Route>
                          <Route path="/mailchimp">
                            <Mailchimp />
                          </Route>
                          <Route path="/">
                            <Home
                              categories={this.state.categories}
                              recentVideos={this.state.recentVideos}
                              ads={this.state.ads}
                              ticker={this.state.ticker}
                              blogs={this.state.blogs}
                            />
                          </Route>
                        </Switch>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <Footer experts={this.state.experts} />
            </div>
          </div>
        </Router>
      </Provider>
    );
  }
}
