import React, { useState, useEffect } from 'react'
import { Row } from 'react-bootstrap'
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { checkout } from '../../fetchService'

const CheckoutForm = ({ packageSubscription, loading, setLoading, callback, errors, setErrors, buyModal }) => {
  const [first, setFirst] = useState('')
  const [last, setLast] = useState('')
  const [email, setEmail] = useState('')
  const [terms, setTerms] = useState(false)
  const [success, setSuccess] = useState(false)
  const stripe = useStripe()
  const elements = useElements()

  const resetData = () => {
    setFirst('')
    setLast('')
    setTerms(false)
    setEmail('')
    setErrors({})
  }

  useEffect(() => {
    resetData()
    // eslint-disable-next-line
  }, [buyModal])

  const handleSubmit = async e => {
    e.preventDefault()

    function validateEmail(email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    }

    const validation = terms && first && last && validateEmail(email)

    if (validation) {
      setLoading(true)
      setErrors({})
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: elements.getElement(CardElement)
      })
    
      
      if (!error) {
        paymentMethod.billing_details.name = `${first} ${last}`
        paymentMethod.billing_details.email = email
        const data = { paymentMethod }
        checkout(packageSubscription.id, data)
          .then((res) => {
            setLoading(false);
            if (typeof res.data === "string") {
              setErrors({ email: `*${res.data}` });
            } else {
              setSuccess(true);
              elements.getElement(CardElement).clear();
              setTimeout(() => {
                callback();
                resetData();
                setSuccess(false);
              }, 5000);
            }
          })
          .catch((err) => {
            setLoading(false);
            console.error(err);
          });
      } else {
        setLoading(false)
        setErrors({cc: error.message})
        console.error(error)
      }
    } else {
      const formErrors = {
        terms: terms? undefined : '*must accept terms & conditions',
        first: first? undefined : '*required',
        last: last? undefined : '*required',
        email: email? validateEmail(email)? undefined : '*not a valid email' : '*required'
      }
      setErrors(formErrors)
    }
  }

  const stripeOptions = {
    style: {
      base: {
        fontSize: '16px',
        color: '#424770',
        '::placeholder': {
          color: '#aab7c4',
        },
      },
      invalid: {
        color: '#9e2146',
      },
    },
  }

  const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  let endDay = new Date()
  const length = parseInt(packageSubscription.duration) / 24
  endDay.setDate(endDay.getDate() + length-1)
  const month = endDay.getMonth()
  const day = endDay.toString().split(' ').splice(2, 2)
  day.unshift(monthNames[month])
  endDay = day.join(' ')

  const imgStyle = { background: `url(${packageSubscription.expert?.profilePic?.url}) no-repeat center / cover`}

  return <div className="picks-sale-box">
    <div className='checkoutImage' style={imgStyle} />
    
    <div className="picks-form-box loading-view" style={{display: loading? 'flex' : 'none'}}>
      <div className="picks-form-display">
        <h3>Loading...</h3>
        <p>Please do not close or refresh tab</p>
      </div>
    </div>
    
    <div style={{display: !loading && success? 'flex' : 'none'}} className="picks-form-box success-view">
      <div className="picks-form-display">
        <h3>Success!</h3>
        <p className="success-msg">{packageSubscription.name} will be sent to {email}</p>
      </div>
    </div>
    
    <form className="picks-form" style={{display: loading || success? 'none' : 'block'}}>
      <p>Please fill out the form below to get {packageSubscription.name} sent straight to your inbox.</p>
      <Row>
        <div className="col-6">
          <label>First Name<span className="form-error-display">{errors.first}</span></label>
          <input value={first} onChange={e => setFirst(e.target.value)} />
        </div>
        <div className="col-6">
          <label>Last Name<span className="form-error-display">{errors.last}</span></label>
          <input value={last} onChange={e => setLast(e.target.value)} />
        </div>
      </Row>
      <Row>
        <div className="col-12">
          <label>Email Address<span className="form-error-display">{errors.email}</span></label>
          <input value={email} onChange={e => setEmail(e.target.value)} /> 
        </div>
      </Row>
      <div>
        <label>Credit Card<span className="form-error-display">{errors.cc}</span></label>
        <div className="card-element-border">
          <CardElement options={stripeOptions} />
        </div>
      </div>
      <div className="price-info">
        <div>
          <h3>{packageSubscription.expert?.username}</h3>
          <p>Get {packageSubscription.name}</p>
          {length === 1? <p>for {endDay}</p> : <p>until {endDay}</p>}
        </div>
        <div className="text-right">
          <h1>${packageSubscription.price}</h1>
          <p>CAD</p>
        </div>
      </div>
      <div className="terms-box pointer">
        <input className="inline form-check" type="checkbox" checked={terms} onChange={e => setTerms(!terms)} />
          <span onClick={e => setTerms(!terms)}>I agree to&nbsp;</span><a className='terms-link' href="/terms" target="_blank" rel="noopener noreferrer"> terms and conditions</a>
      </div>
      <div style={{textAlign: "center", fontSize: "12px"}} className="form-error-display">{errors.terms}</div>
      <button onClick={handleSubmit}>BUY NOW</button>
    </form>
  </div>
}

export default CheckoutForm