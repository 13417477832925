import { Component } from "react";
import Post from "../Post/Post";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "./RecentVideos.scss";

export default class RecentVideos extends Component {
  render() {
    return (
      <div>
        <div className="row mt-4"></div>
        <div className="splide">
          <Splide
            // https://splidejs.com/options/
            options={{
              type: "loop",
              // perPage: 4,
              trimSpace: true,
              gap: 30,
              pagination: false,
              autoWidth: true,
            }}
          >
            {this.props.videos?.slice(0, 16).map((item, id) =>
              item.privacy.view === "anybody" && item.status === "available" ? (
                <SplideSlide key={id}>
                  <Post data={item}></Post>
                </SplideSlide>
              ) : (
                ""
              )
            )}
          </Splide>
        </div>
      </div>
    );
  }
}
