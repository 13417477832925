import facebook from "../../images/facebook-DarkBlue.png";
import twitter from "../../images/twitter-DarkBlue.png";
import instagram from "../../images/instagram-DarkBlue.png";
import youtube from "../../images/youtube-DarkBlue.png";
import { Link } from "react-router-dom";
import "./Footer.scss";
import Mailchimp from "../Mailchimp";
import { Component } from "react";

export default class Footer extends Component {

  render() {
    return (
      <footer className="row footer bg-gray m-0">
        <div className="col-12">
          <div className="row">
            <div className="col-lg-10 d-flex SocialMediaIcons">
              <div className="row ">
                <div className="col-lg-3">
                  <Link to={"/contact"} className="footer-nav-link icon pl-3">
                    CONTACT US
                  </Link>
                </div>
                <div className="col-lg-4">
                  <Link
                    to={"/terms"}
                    className="footer-nav-link icon pl-3"
                    onClick={() => {window.scrollTo(0, 0)}}
                  >
                    TERMS AND CONDITIONS
                  </Link>
                </div>
                <div className="col-lg-5">
                  <Mailchimp />
                </div>
              </div>
            </div>

            <div className="col-lg-2 text-center pt-3 pb-3 SocialMediaIcons">
              <a
                className="icon"
                href="https://twitter.com/gamechangerzpvn"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={twitter}
                  className="social-media mr-4 ml-2"
                  alt="Game Changerz Twitter"
                />
              </a>
              <a
                className="icon"
                href="https://facebook.com/gamechangerzpvn"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={facebook}
                  className="social-media mr-4 ml-2"
                  alt="Game Changerz Facebook"
                />
              </a>
              <a
                className="icon"
                href="https://instagram.com/gamechangerzpvn"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={instagram}
                  className="social-media mr-4 ml-2"
                  alt="Game Changerz Instagram"
                />
              </a>
              <a
                className="icon"
                href="https://www.youtube.com/channel/UC6ORt2aQLCwuRqmh7pLBBqg"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={youtube}
                  className="social-media mr-4 ml-2"
                  alt="Game Changerz Instagram"
                />
              </a>
            </div>
          </div>

          <div className="row">
            <div className="col-7 pl-5">
              <p className="medium-font black">
                            Copyright &copy; 2021 Enteractive Media INC. All rights reserved {" "}
                <br />
                A <a href="http://headsupentertainment.com/" target="_blank">Heads Up Entertainment Inc.</a> Property, Published by Enteractive Media Inc.
                <br />
                Use of this website (including any and all part and components)
                <br />
                consittutes your acceptence of our <b><a href="/terms">Terms of Use</a> policies.</b>
              </p>
            </div>

            <div className="col-5 bold pr-5 text-right SocialMediaIcons DisableOnMobile">
              {/* <h5 className="text-uppercase mb-0">Links</h5> */}
              <Link className="pr-3 footer-nav-link icon" to="/">
                Home
              </Link>
              <Link className="pr-3 footer-nav-link icon" to="/blog">
                Stories
              </Link>
              <Link className="pr-3 footer-nav-link icon" to="/videos">
                Videos
              </Link>
              <Link className="pr-3 footer-nav-link icon" to="/experts">
                Experts
              </Link>
              <Link className="pr-3 footer-nav-link icon" to="/calendar">
                Calendar
              </Link>
              <Link className="pr-3 footer-nav-link icon" to="/contact">
                Contact
              </Link>
            </div>
          </div>

                <div className="row">
            <h4 className="col-12 text-right pr-4 gray ">Experts</h4>
          </div>

          <div className="row">
            <div className="col-12 text-right pb-5">
              <div className="ml-auto medium-font bold SocialMediaIcons">
                {this.props.experts?.map((expert, id) => (
                  <Link
                    to={
                      "/experts/" +
                      expert.username.replace(/\s+/g, "-").toLowerCase()
                    }
                    className="pr-3 footer-nav-link icon"
                    key={id}
                  >
                    {expert.username}
                  </Link>
                ))}
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}
