import React from "react";
import ContactUs from "./ContactUs/ContactUs";
import StayUpdated from "./StayUpdated/StayUpdated";
import Ad from "./Ad/Ad";
export default function Contact(props) {
  return (
    <div className="row">
      <div className="col-lg-10">
        <ContactUs />
      </div>
      <div className="col-2 DisableOnMobile">
        <div className="mt-4 mb-4 ">
          <StayUpdated content={props.blogs}></StayUpdated>
        </div>
        <div className="mt-4 mb-4 ">
          {/*u must replace Ad componanent with the right width and height componanet imade in the AhmadAdds branch */}
          <Ad type="skyscraper" ads={props.ads} />
        </div>
      </div>
    </div>
  );
}
