import "./MainVideoPlayer.scss";

export default function MainVideoPlayer(props) {
  const videoId =
    props.videoId ||
    props.video?.uri?.substr(props.video?.uri?.lastIndexOf("/") + 1);
  return videoId ? (
    <div className="MainVideoPlayer">
      <div
        dangerouslySetInnerHTML={{
          __html: `<div><iframe class="responsive-iframe" src="https://player.vimeo.com/video/${videoId}?title=0&byline=0=&portrait=0" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe></div>`,
        }}
      ></div>
    </div>
  ) : (
    <div></div>
  );
}
