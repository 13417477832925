import "./SideNav.scss";
import { Link } from "react-router-dom";
import SocialMediaIcons from "../SocialMediaIcons/SocialMediaIcons";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Ad from "../Ad/Ad";
import { Component } from "react";
export default class SideNav extends Component {
  render() {
    return (
      <div className="LeftColumn">
        <Navbar fixed="top" expand="xl">
          <Navbar.Toggle aria-controls="basic-navbar-nav" id="navbarToggle" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto">
              <ul className="side-nav nav flex-column float-left ml-5">
                <li className="nav-item ">
                  <Link
                    to="/"
                    className="nav-link active"
                    aria-current="page"
                    onClick={closeNavbar}
                  >
                    HOME
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/blog" className="nav-link" onClick={closeNavbar}>
                    STORIES
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/videos" className="nav-link" onClick={closeNavbar}>
                    VIDEOS
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to={"/experts"}
                    className="nav-link"
                    onClick={closeNavbar}
                  >
                    EXPERTS
                  </Link>
                </li>
                {/* <li className="nav-item">
                <Link to="/calender" className="nav-link" onClick={closeNavbar}>
                  CALENDAR
                </Link>
              </li> */}
                <li className="nav-item">
                  <Link
                    to="/contact"
                    className="nav-link"
                    onClick={closeNavbar}
                  >
                    CONTACT
                  </Link>
                </li>
                <li className="mt-5 " onClick={closeNavbar}>
                  <SocialMediaIcons />
                </li>

                <li className="mt-5 pr-5 LeftMenu" onClick={closeNavbar}>
                  <div className="mt-4 mb-4 nav-square-ad">
                    <Ad type="square" ads={this.props.ads}></Ad>
                  </div>
                </li>
                {/* SEO text */}
                {/* <div className="mt-4 mb-4 SeoText">
                  <div></div>
                </div>
              </div>  */}
              </ul>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
    );
  }
}
function closeNavbar() {
  document.querySelector("#navbarToggle")?.click();
}
