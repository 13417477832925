import "./Divider.scss";
export default function Divider(props) {
    return (
        <div className="row">
            <div className="col-12 divider">
                <h3 className="dividerTitle">{props.text}</h3>
                <div className="horizontal">
                    <div>{/* Horizental Line */}</div>
                </div>
            </div>
        </div>
    );
}
