import { Posts } from "../../constants/posts";
import Divider from "../Divider/Divider";
import Post from "../Post/Post";
import RecentVideos from "../RecentVideos/RecentVideos";
import RecommendedVideos from "../RecommendedVideos/RecommendedVideos";
import facebook from "../../images/facebook.png";
import twitter from "../../images/twitter.png";
import instagram from "../../images/instagram.png";
import "./Experts.scss";
import { Component } from "react";
import { withRouter } from "react-router-dom";

class ExpertInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    //window.scrollTo(0, 0);
    window.twttr.widgets.load();
    let name = this.props.match?.params?.expertId;
    if (!name) {
      this.setState({ expertId: 0 });
    } else {
      name = name.replace(/-/g, " ");
      let index = this.getExpertIndex(name);
      this.setState({ expertId: index });
    }
    // this.handelClick = this.handelClick.bind(this);
  }
  componentDidUpdate(prevProps) {
    if (
      prevProps.location.key !== this.props.location.key ||
      this.props.experts !== prevProps.experts
    ) {
      let name = this.props.match?.params?.expertId;
      document
        .querySelector(".sidebar-box")
        ?.classList.remove("max-height-9999");
      if (!name) {
        this.setState({ expertId: 0 });
      } else {
        name = name.replace(/-/g, " ");
        let index = this.getExpertIndex(name);
        this.setState({ expertId: index });
      }
      window.twttr.widgets.load();
    }
  }
  getPosts() {
    return Posts;
  }
  setBuyModal = () => {
    this.setState({ buyModal: !this.state.buyModal });
  };

  selectPackage = (selection, expert) => {
    selection.expert = expert;
    this.setState({ selectedPackage: selection, buyModal: true });
  };
  getExpertIndex(name) {
    for (let i = 0; i < this.props.experts.length; i++) {
      if (!this.props.experts[i].username.toLowerCase().localeCompare(name)) {
        return i;
      }
    }
  }

  handelClick(id) {
    let sidebar = document.querySelector(`#${id}`);
    sidebar.classList?.add("max-height-9999");
  }

  render() {
    let expert = this.props.experts[this.state.expertId];
    if (expert !== undefined) {
      const names = expert?.username.split(" ");
      return (
        <div>
          {this.props.experts
            ?.slice(0)
            .reverse()
            .map((expert, i) => (
              <div
                className="row"
                style={{ paddingTop: i != 0 ? "6.8rem" : "2.5rem" }}
                id={expert?.username.replace(/\s/g, "-")}
              >
                <div className="col-lg-3 mr-5">
                  <img
                    src={expert?.profilePic?.url}
                    className="img-fluid expert-img"
                    alt=""
                  />
                </div>
                <div className="col-lg-8">
                  <div className="row">
                    <div className="col-12 h1 expert-name pr-2 ">
                      <h1 className="orange">{expert?.username}</h1>
                    </div>
                  </div>
                  <div className="row ">
                    <div
                      id={`p-${expert?.id}`}
                      className="sidebar-box col-10 expert-bref h4 pt-4"
                    >
                      <p dangerouslySetInnerHTML={{ __html: expert?.bio }}></p>
                      {/* show read more in case bio is long */}
                      {expert.bio?.length > 500 ? (
                        <p className="read-more">
                          <button
                            className="button"
                            onClick={this.handelClick.bind(
                              this,
                              `p-${expert?.id}`
                            )}
                          >
                            Read More
                          </button>
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="row ">
                    <div className="col-12 h4 pt-4">
                      <h2 className="dark-yellow">
                        Get {names ? names[0] : ""}'s Picks Now!
                      </h2>
                    </div>
                  </div>

                  <div className="row pt-4">
                    {expert?.packages.length ? (
                      expert?.packages.map((pickPackage) => (
                        <div
                          onClick={() =>
                            this.props.selectPackage(pickPackage, expert)
                          }start build
                          
                          key={pickPackage?.name}
                          type="button"
                          className="btn daily-picks mr-4 mt-3"
                        >
                          <b>{pickPackage.name}</b>
                          <h1 className="dark-blue">${pickPackage.price}</h1>
                          <button>Buy Now</button>
                        </div>
                      ))
                    ) : (
                      <p className="form-error-display success-msg">
                        No Picks available right now.
                      </p>
                    )}
                  </div>

                  {expert.twitter || expert.twitter || expert.twitter ? (
                    <div className="row pt-4">
                      <div className="col-lg-12">
                        <h5 className="dark-yellow">Follow {names[0]}!</h5>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="row col-lg-5 mt-4">
                    {expert.twitter ? (
                      <a
                        href={`https://twitter.com/${expert.twitter}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="col-1 mr-4 p-0"
                      >
                        <img
                          src={twitter}
                          alt=""
                          className="img-fluid img-white-filter social"
                        />
                      </a>
                    ) : (
                      ""
                    )}
                    {expert.facebook ? (
                      <a
                        href={`https://facebook.com/${expert.facebook}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="col-1 mr-4 p-0"
                      >
                        <img
                          src={facebook}
                          alt=""
                          className="img-fluid img-white-filter social"
                        />
                      </a>
                    ) : (
                      ""
                    )}
                    {expert.instagram ? (
                      <a
                        href={`https://instagram.com/${expert.instagram}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="col-1 mr-4 p-0"
                      >
                        <img
                          src={instagram}
                          alt=""
                          className="img-fluid img-white-filter social"
                        />
                      </a>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            ))}
          {/* videos */}
          <div className="row pt-5">
            <div className="col-lg-3 mr-5">
              <div className="">
                <Post data={this.getPosts()[0]}></Post>
              </div>
              <div className="pt-3">
                <Post data={this.getPosts()[0]}></Post>
              </div>
            </div>
            <div className="col-lg-8 h1 ">
              {/* https://developer.twitter.com/en/docs/twitter-for-websites/timelines/guides/oembed-api */}
              <a
                data-theme="dark"
                className="twitter-timeline"
                data-height="350"
                href={`https://twitter.com/${expert.twitter}?ref_src=twsrc%5Etfw`}
              >
                &nbsp;
              </a>
            </div>
          </div>
          <Divider text={"RECENT VIDEOS"}></Divider>
          <div className="row mb-4">
            <div className="col-12">
              <RecentVideos
                categories={this.props.categories}
                videos={this.props.recentVideos}
              ></RecentVideos>
            </div>
          </div>

          <Divider className="pt-5" text={"RECOMMENDED"}></Divider>
          <div className="row mb-4">
            <div className="col-12">
              <RecommendedVideos
                categories={this.props.categories}
                videos={this.props.recommendedVideos}
              ></RecommendedVideos>
            </div>
          </div>
        </div>
      );
    } else return <div></div>;
  }
}
export default withRouter(ExpertInfo);
