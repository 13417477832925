import axios from "axios";
import { Vimeo } from "vimeo";
const backendHost = "https://api.gamechangerz.ca";
const vimeoClient = new Vimeo(
  process.env.REACT_APP_VIMEO_CLIENT_ID,
  process.env.REACT_APP_VIMEO_CLIENT_SECRET,
  process.env.REACT_APP_VIMEO_ACCESS_TOKEN
);
const maxRecVids = 40;

export async function fetchBlogs() {
  const response = await fetch(`${backendHost}/contents?_sort=created_at:DESC`);
  return await response.json();
}

export async function fetchBlog(id) {
  const response = await fetch(`${backendHost}/contents/${id}`);
  return await response.json();
}
export async function fetchBlogByCat(categories) {
  const selectedCategories = categories?.filter(
    (item) => item.visible === true
  );
  let query = "?";
  if (selectedCategories?.length > 0) {
    selectedCategories.forEach(
      (cat) => (query += `category.name_in=${encodeURIComponent(cat.name)}&`)
    );
  }
  const response = await fetch(`${backendHost}/contents${query}&_sort=created_at:DESC`);
  return await response.json();
}
export async function fetchBlogByTitle(title) {
  title = title.toLowerCase() ; 
  const response = await (await fetch(`${backendHost}/contents`)).json();
  for (let i = 0; i < response.length; i++) {
    if (response[i].header.toLowerCase().replace(/[^a-zA-Z0-9-_ ]/g, '') === title) {
      return response[i];
    }
  }
  return null;
}
export async function fetchAds() {
  // const response = await fetch(`${backendHost}/ads?type=${type}`);
  const response = await fetch(`${backendHost}/ads`);
  return await response.json();
}
export async function fetchExperts() {
  const response = await fetch(`${backendHost}/experts`);
  return await response.json();
}
export async function fetchExpertByName(name) {
  const response = await (await fetch(`${backendHost}/experts`)).json;
  for (let i = 0; i < response.length; i++) {
    if (response[i].username === name) return response[i];
  }
  return null;
}
export async function fetchCategories() {
  const response = await fetch(`${backendHost}/categories`);
  return await response.json();
}
export async function fetchTickerbarContent() {
  const response = await fetch(`${backendHost}/users`);
  return await response.json();
}

export async function sendContact(data) {
  // const method = "POST";
  //const url = `${backendHost}/email/contact-us`;
  // const response = await axios({ method, url, data });
  // return response;
}


export async function checkout(id, data) {
  const method = "POST";
  const url = `${backendHost}/packages/${id}`;
  const response = await axios({ method, url, data });
  return response;
}

export async function getVimeoVideo(id) {
  return new Promise((resolve, reject) => {
    // get albums
    vimeoClient.request(
      {
        method: "GET",
        path: `/videos/${id}`,
      },
      (error, body, status_code, headers) => {
        if (error) {
          reject(error);
        } else {
          resolve(body);
        }
      }
    );
  });
}

export async function getRecentVideos(categories) {
  // return getViemeoVideos(categories, "sort=date&direction=desc&per_page=8");
  return getViemeoVideos("sort=date&direction=desc&per_page=8");
}
export async function getRecommendedVideos(categories) {
  const selectedCategories = categories?.filter(
    (item) => item.visible === true
  );
  if (selectedCategories?.length > 0) {
    // const perPage = Math.round(maxRecVids / selectedCategories.length);
    // return getViemeoVideos(
    //   categories,
    //   `sort=plays&direction=desc&per_page=${perPage}`
    // );
    return getViemeoVideos(`sort=plays&direction=desc&per_page=${maxRecVids}`);
  }
}

async function getViemeoVideos(query) {
  return new Promise((resolve, reject) => {
    // get albums
    vimeoClient.request(
      {
        method: "GET",
        path: `/me/videos?${query}`,
      },
      (error, body, status_code, headers) => {
        if (error) {
          reject(error);
        }
        resolve(body.data);
      }
    );
  });
}

// async function getViemeoVideosByCat(categories, query) {
//   if (!categories || categories.length === 0) {
//     return [];
//   } else {
//     return new Promise((resolve, reject) => {
//       // get albums
//       vimeoClient.request(
//         {
//           method: "GET",
//           path: "/me/albums",
//         },
//         (error, body, status_code, headers) => {
//           if (error) {
//             reject(error);
//           }
//           const filteredAlbums = [];
//           body.data.forEach((album) => {
//             const category = categories.find((cat) => cat.name === album.name);
//             // if the category (album) is selected
//             if (category?.visible) {
//               filteredAlbums.push(album);
//             }
//           });
//           const promiseA = [];
//           // for each filtered album
//           filteredAlbums.forEach((album) => {
//             const promise = new Promise((res, rej) => {
//               vimeoClient.request(
//                 {
//                   method: "GET",
//                   path: `${album.uri}/videos?${query}`,
//                 },
//                 (error2, categoryVideos, status_code, headers) => {
//                   if (error2) {
//                     rej(error2);
//                   } else {
//                     res(categoryVideos.data);
//                   }
//                 }
//               );
//             });
//             promiseA.push(promise);
//           });
//           // execute all requests
//           Promise.all(promiseA)
//             .then((result) => {
//               const final = [];
//               result.forEach((itemArray) => {
//                 itemArray.forEach((item) => {
//                   final.push(item);
//                 });
//               });
//               resolve(final);
//             })
//             .catch((err) => reject(err));
//         }
//       );
//     });
//   }
// }
