import logo from "../../images/logo.png";
import "./Header.scss";
import Ad from "../Ad/Ad";
import { Component } from "react";

export default class Header extends Component {
  render() {
    return (
      <div className="row position-fixed header text-right">
        <div className="col-lg-2 logo text-right">
          <a href="/">
            <img src={logo} className="img-fluid" alt="logo" />
          </a>
        </div>

        <div className="col-lg-5 leaderboard">
          <Ad type="leaderboard" ads={this.props.ads}></Ad>
        </div>

        <div className="col-lg-5 HeaderRightSide text-right">
          <h1 className="float-right">GIVING YOU THE BETTING EDGE</h1>
          {/* <ul className="nav header-nav">
            <li className="nav-item">
              <a
                className="nav-link"
                onClick={(e) => {
                  this.props.changeCategory("ALL");
                }}
              >
                ALL
              </a>
            </li>
            {this.props.categories?.map((category, i) => (
              <li className="nav-item" key={i}>
                <a
                  className={
                    category.visible ? "nav-link FilterActive" : "nav-link"
                  }
                  onClick={(e) => {
                    this.props.changeCategory(category.name);
                  }}
                >
                  {category.name}
                </a>
              </li>
            ))}
          </ul> */}
        </div>
      </div>
    );
  }
}
